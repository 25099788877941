@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css?family=Inter'); */
:root {
  --primaryColor: #ff826a;
  --secondaryColor: #22a8ff;
  --tertiaryColor: #00C171;
  --xdGrey: #726D6D;
}

* {
  font-family: sans-serif ;
  transition: 0.07s ease-in all;
}

#container {
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  overflow-y: auto;
  height: 100vh;
}

#menu {
  display: none;
  /* position: absolute;
  right: 27px;
  top: 23px */
}

#messageBoxOverlay {
  z-index: 5;
  position: fixed;
  background-color: #9191916a;
  height: 100%;
  width: 100%;
  display: none;
}

#messageBox {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 15px;
  border-radius: 15px;
  text-align: center;
}

#messageBoxButton {
  background-color: #22a8ff;
  background-color: var(--secondaryColor);
  color: white;
  border-color: white;
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
  font-size: 14px;
}

#contactBoxOverlay {
  z-index: 5;
  position: fixed;
  background-color: #9191916a;
  height: 100%;
  width: 100%;
  display: none;
}

#contactBox {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 30px;
  border-radius: 50px;
  text-align: center;
  min-width: 50%;
}

#contactBoxButton {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 20px;
  z-index: 3;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #22a8ff;
  background-color: var(--secondaryColor);
}

#contactBoxClose {
  fill: white;
  width: 20px;
}

.socialIcon {
  padding: 10px;
  fill: #ff826a;
  fill: var(--primaryColor);
  width: 100px;
  cursor: pointer;
}

.socialIcon:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  transition-duration: 100ms;
}

button {
  cursor: pointer;
}

#navBar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px;
  /* position: sticky; */
  position: relative;
}

#navBarLogo {
  height: 34px;
  margin-right: 10px;
}

#navBarWrittenLogo {
  height: 34px;
}

#navBarLinks {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.navBarLink {
  padding: 0px 14px;
  color: #ff826a;
  color: var(--primaryColor);
  font-weight: bold;
  cursor: pointer;
}

.blue {
  color: #22a8ff;
  color: var(--secondaryColor);
}

.salmon {
  color: #ff826a;
  color: var(--primaryColor);
}

.green {
  color: #00C171;
  color: var(--tertiaryColor);
}

.mainTile {
  margin: 0 7vw;
  text-align: center;
}

.snapArea {
  scroll-snap-align: start;
}

.page {
  height: 100vh;
}

#intro {
  height: 85vh;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}

#intro>div:nth-child(1) {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 65%
}

#intro>div:nth-child(2) {
  width: 35%;
  margin-left: 20PX;
  margin-right: 10vw;
  height: 85%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center
}

#intro>div:nth-child(2)>img {
  max-height: 100%;
  max-width: 100%;
}

#backgroundShape1 {
  background-color: #ff826a;
  background-color: var(--primaryColor);
  height: 100%;
  width: 28%;
  position: absolute;
  z-index: -1;
  right: 0px;
  margin-right: 0
}

#cooking {
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}

#cooking>div:nth-child(1) {
  width: 50%;
  margin-right: 20PX;
  height: 90%;
}

#cooking>div:nth-child(1)>img {
  max-height: 100%;
  max-width: 100%;
}

#cooking>div:nth-child(2) {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 50%
}

#backgroundShape2 {
  display: none;
  z-index: -1;
}

#backgroundShape3 {
  display: none;
  z-index: -1;
}

#exchange>div {
  -webkit-justify-content: space-around;
          justify-content: space-around;
  height: 50%
}

#chat {
  position: relative;
}

.headline {
  font-size: clamp(22.8px, 2.9vw, 60px);
  text-align: start;
  font-weight: 700;
}

.transition {
  /* font-size: calc(7px + 3.5vw); */
  /* font-size: 3vw; */
  font-size: clamp(28px, 3vw, 65px);
  text-align: center;
  /* font-family: Indie Flower; */
  font-weight: 700;
}

.leading {
  font-size: clamp(20px, 2.5vw, 50px);
  /* font-size: 3vw; */
  text-align: start;
  font-weight: 700;
}

.following {
  font-size: clamp(18px, 2vw, 40px);
  font-weight: 300;
  text-align: start;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 15%;
  text-decoration-thickness: 20%;
  /* text-decoration-thickness: clamp(1px, 25%, 10px); */
}

.phoneText {
  font-family: Roboto;
  color: #726D6D;
  color: var(--xdGrey);
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.exchangeMethodIcon {
  width: 20vw;
}

.center {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #F1F1F1;
}

::-webkit-scrollbar-thumb {
  background: #22a8ff;
  background: var(--secondaryColor);
  /* border-radius: 10px; */
}

::-webkit-scrollbar-thumb:hover {
  background: #ff826a;
  background: var(--primaryColor);
}

#footer {
  text-align: center;
  color: #22a8ff;
  color: var(--secondaryColor);
  padding: 20px 60px;
}

#footer a {
  color: #22a8ff;
  color: var(--secondaryColor);
  text-decoration: none;
}

#reviews>:nth-child(1) {
  height: 35%;
}

#reviews>:nth-child(1)>img {
  height: 70%;
}

#reviews>:nth-child(2) {
  height: 60%;
}

#reviews>:nth-child(2)>div {
  width: 40%;
  padding: 5%;
}

#reviews>:nth-child(2)>div>img {
  max-width: 100%;
  max-height: 100%;
}

#reviews>:nth-child(2)>div>p {
  text-align: center;
}

#reviews>:nth-child(2)>:nth-child(1)>img {
  margin: 10% 0;
}

#location {
  -webkit-justify-content: start;
          justify-content: start;
  -webkit-align-items: center;
          align-items: center
}

#location>:nth-child(1) {
  width: 100%;
  height: 15%;
  display: grid;
  place-content: center;
  /* margin:0px */
}

#location>:nth-child(2) {
  width: 100%;
  height: 75%
}

#location>:nth-child(2)>div {
  width: 50%;
  height: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center
}

#location>:nth-child(2)>div>img {
  height: 80%;
  max-width: 100%
}

#download {
  position: relative;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  /* margin-top: 72px; */
  margin-bottom: 72px;
}

#download>:nth-child(1) {
  height: 17vh;
  margin-top: 10px
}

#download>:nth-child(2) {
  font-family: Indie Flower;
  font-weight: normal;
  color: #ff826a;
  color: var(--primaryColor);
  font-size: clamp(30px, 4vw, 60px);
  text-align: center;
  margin: 0;
  margin-bottom: 1.2rem;
}

#download>:nth-child(3) {
  /* font-size: 2.5vw; */
  text-align: center
}

#download>:nth-child(4) {
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  /* flex-grow: 1; */
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin-top: 7vh;
}

#download>:nth-child(4)>img {
  padding: 5%;
  width: clamp(200px, 20vw, 500px);
  cursor: pointer;


  
}

@media only screen and (max-width:500px) {
  #navBar {
    -webkit-flex-direction: column;
            flex-direction: column;
    /* align-items: flex-start; */
  }
  #navBar.expanded {
    box-shadow: 0 0 21px 0 #d3d3d3;
  }
  #menu {
    display: inline;
    display: initial;
  }
  #navBarLinks {
    padding: 17px 0;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    height: auto;
    width: 100%;
  }
  #navBarLinks.invisible {
    padding: 0px 0;
    max-height: 0px;
    overflow: hidden;
  }
  .navBarLink {
    padding: 7px 14px;
  }
  #navBarLogo {
    height: 25px;
    margin-right: 10px;
  }
  #navBarWrittenLogo {
    height: 25px;
  }
}

@media only screen and (max-width:600px) {
  #container {
    -ms-scroll-snap-type: none;
        scroll-snap-type: none;
  }
  .snapArea {
    scroll-snap-align: none;
  }
  .page {
    height: auto;
    height: initial;
  }
  .socialIcon {
    width: 50px;
  }
  #contactBox {
    /* margin: 0px; */
    padding: 15px;
    border-radius: 40px;
    min-width: 70%;
  }
  #intro {
    -webkit-flex-direction: column;
            flex-direction: column;
    height: auto;
    height: initial;
  }
  #intro>div:nth-child(1) {
    width: 100%;
    margin: 10vh 0 2vh 0;
  }
  #intro>div:nth-child(1)>p {
    /* margin:0 15vh; */
    margin-left: 3vw;
    margin-right: 3vw;
    margin-bottom: 20px;
    text-align: center;
  }
  #intro>div:nth-child(2) {
    width: 100%;
    margin: 0;
    margin: initial;
  }
  #intro>div:nth-child(2)>img {
    width: 90%;
    height: 90%;
    margin-bottom: 10%;
  }
  #backgroundShape1 {
    /* display: none; */
    width: 90vw;
    height: 50%;
    bottom: 0%;
    right: unset;
    margin-right: 0;
    margin-right: initial;
  }
  #cooking {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    /* background-image: url("..../assets/img2.png"); */
  }
  #cooking>div:nth-child(1) {
    width: 100%;
    margin: 0;
    margin: initial;
    height: auto;
    height: initial;
  }
  #cooking>div:nth-child(1)>img {
    width: 90%;
    max-height: none;
    max-height: initial;
    max-width: none;
    max-width: initial;
    padding-bottom: 10%;
  }
  #cooking>div:nth-child(2) {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 100%
  }
  #cooking>div:nth-child(2)>p {
    margin-left: 3vw;
    margin-right: 3vw;
    margin-bottom: 40px;
    text-align: center;
  }
  #backgroundShape2 {
    background-color: #ff826a;
    background-color: var(--primaryColor);
    display: inline;
    display: initial;
    height: 60%;
    width: 45vw;
    position: absolute;
    bottom: 2%;
    right: 0;
  }
  #backgroundShape3 {
    background-color: #ff826a;
    background-color: var(--primaryColor);
    display: inline;
    display: initial;
    height: 4%;
    width: 45vw;
    position: absolute;
    bottom: 2%;
    left: 0px;
    -webkit-transform: skew(-10deg);
            transform: skew(-10deg);
  }
  #exchange {
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
  #exchange>div {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    height: 50%
  }
  #exchange>div>div>img {
    width: 25vh;
  }
  #chat {
    height: 100vh;
  }
  #reviews {
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
  #reviews>:nth-child(1) {
    height: auto;
    height: initial;
    margin-bottom: 3vh;
  }
  #reviews>:nth-child(1)>img {
    height: 20vh;
  }
  #reviews>:nth-child(2) {
    height: auto;
    height: initial;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  #reviews>:nth-child(2)>div {
    width: 100%;
    padding: 5%;
  }
  #reviews>:nth-child(2)>div>img {
    max-width: 100%;
    max-height: 100%;
  }
  #reviews>:nth-child(2)>div>p {
    text-align: center;
    margin: auto 10%;
  }
  #reviews>:nth-child(2)>:nth-child(1)>img {
    margin-top: 7%;
    margin-bottom: 7%;
  }
  #location {
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
  }
  #location>:nth-child(1) {
    width: 100%;
    margin: 10vh auto;
    display: grid;
    place-content: center;
    height: auto;
  }
  #location>:nth-child(2) {
    width: 100%;
    /* height: 75%; */
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  #location>:nth-child(2)>div {
    width: 100%;
    height: 100%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center
  }
  #location>:nth-child(2)>div>img {
    padding: 5%;
    height: auto;
    max-width: 100%
  }
  #download>:nth-child(4) {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  #footer {
    font-size: 80%;
  }
}
